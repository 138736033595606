import { useEffect, useState } from "react";

import { useRouter } from "next/router";
import { ApolloProvider } from "@apollo/client";
import { SessionProvider } from "next-auth/react";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

import { PageProps } from "@/types/page";
import "../../polyfills";
import { useApollo } from "@/API/index";
import "../styles/global.scss";
import { init as sentry } from "@/helpers/sentry";
import { GTMPlugin } from "@/helpers/amplitudePlugins";
import { Maintenance } from "@/pagesComponents/Maintenance/Maintenance";
import { ExperimentsProvider } from "@/providers/ExperimentsProvider";

import { useUserDataStore } from "../store";
import { track } from "../helpers";

sentry();

const App = ({ Component, pageProps, err }: PageProps) => {
	const [history, setHistory] = useState<string[]>([]);
	const [userConsent] = useUserDataStore((state) => [state.userConsent]);

	const router = useRouter();

	useEffect(() => {
		if (router.isReady) {
			if (typeof window !== "undefined") {
				let newHistory = [...history];
				if (history.length === 0) {
					newHistory = [window.document.referrer];
					setHistory(newHistory);
				}

				if (window.location.href !== history[history.length - 1]) {
					newHistory = [...history, window.location.href];
					setHistory(newHistory);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router]);

	useEffect(() => {
		amplitude.add(new GTMPlugin("GTM-TXKV9CF"));

		const sessionReplayTracking = sessionReplayPlugin({
			sampleRate: 0.1,
		});
		amplitude.add(sessionReplayTracking);
	}, []);

	useEffect(() => {
		if (
			!window.location.href.includes("localhost") &&
			userConsent?.functional
		) {
			amplitude.init(process.env.NEXT_PUBLIC_AMP || "", {
				defaultTracking: {
					formInteractions: false,
					fileDownloads: false,
				},
			});
		}

		if (!window) {
			return;
		}
		function gtag () {
			window.dataLayer.push(arguments);
		}

		if (userConsent?.marketing) {
			// @ts-expect-error mismatched versions
			gtag("consent", "update", {
				ad_storage: "granted",
				analytics_storage: "granted",
				functionality_storage: "granted",
				personalization_storage: "granted",
				security_storage: "granted",
				ad_user_data: "granted",
				ad_personalization: "granted",
			});

			track("Consent Accept All");
		}

		if (!userConsent?.marketing && userConsent?.functional) {
			window?.dataLayer?.push("consent", "update", {
				ad_storage: "granted",
				analytics_storage: "granted",
				functionality_storage: "granted",
				personalization_storage: "granted",
				security_storage: "granted",
			});

			track("Consent Accept Functional");
		}
	}, [userConsent]);

	const apolloClient = useApollo(pageProps.initialApolloState);

	if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "true") {
		return <Maintenance />;
	}


	return (
		<SessionProvider>
			<ApolloProvider client={apolloClient}>
				<ExperimentsProvider>
					<Component {...pageProps} err={err} history={history} />
				</ExperimentsProvider>
			</ApolloProvider>
		</SessionProvider>
	);
};

// Disable as this is for next.js
// eslint-disable-next-line import/no-default-export
export default App;
