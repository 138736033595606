import {
	createContext, useCallback, useEffect, useMemo, useState 
} from "react";

import { Experiment } from "@amplitude/experiment-js-client";

import { useUserDataStore } from "@/store/UserDataStore";

import { ABVariants, AmplitudeExperiments, ExperimentContext, ExperimentItem } from "./ExperimentsProvider.types";

export const AmplitudeExperimentContext = createContext<ExperimentContext>({
	loading: true,
	variants: {}
});


export const getAmplitudeExperiment = async (variantKey: string, user_id?: string) => {
	const experiment = Experiment.initializeWithAmplitudeAnalytics(
		process.env.NEXT_PUBLIC_AMP_EXP_DEPLOY || "",
		{
			automaticFetchOnAmplitudeIdentityChange: true,
		}
	);

	user_id
		? await experiment?.start({ user_id: user_id })
		: await experiment?.start()

	/**
     * If using user_id for experiments for specific users, use the fetch method
     * to get most up to date variants. Prevents need for a page refresh if user changes
     * e.g switching between sales person
     */
	const userExperiment = user_id
		? await experiment.fetch({ user_id })
		: null

	const getVariant = () => {
		return userExperiment
			? userExperiment.variant(variantKey).key
			: experiment.variant(variantKey).key;
	}

	return getVariant;
}

export const ExperimentsProvider = ({ children }: Record<string, any>) => {

	const [experiments, setExperiments] = useState<ABVariants>({});
	const [experimentsLoading, setExperimentsLoading] = useState(false);
	
	const [user, userConsent] = useUserDataStore((state) => [state.user, state.userConsent]);

	// Add experiments here, id optional if experiment is used on particular cohort/id's
	const experimentsToFetch: ExperimentItem[] = useMemo(() => Object.values(AmplitudeExperiments).map((key) => ({
		key,
		user_id: user.email
	})), [user?.email]);

	const fetchExperiments = useCallback(async () => {

		setExperimentsLoading(true);

		const result: ABVariants = {};

		await Promise.all(
			experimentsToFetch.map(async (experiment) => {
				const getVariant = await getAmplitudeExperiment(experiment.key, experiment.user_id);
				// create getters, so we can still access the property in the same way, but the event won't run until it's called
				Object.defineProperty(result, experiment.key, {
					get () {
						return getVariant();
					}
				}); 
			})
		);

		setExperiments(result);
		setExperimentsLoading(false);
	}, [experimentsToFetch]);

	useEffect(() => {
		if (!userConsent?.functional || window.location.href.includes("localhost")) {
			return;
		}

		fetchExperiments();
	}, [fetchExperiments, user, userConsent?.functional]);


	const experimentsContext = useMemo(() => ({
		loading: experimentsLoading,
		variants: experiments,
	}), [experiments, experimentsLoading]);

	return (
		<AmplitudeExperimentContext.Provider value={experimentsContext}>
			{children}
		</AmplitudeExperimentContext.Provider>
	)
};