export enum AmplitudeExperiments {
  RECIPEGRID = "recipe-grid",
  NOPROBLEMS = "no-problems",
  REVIEWPLAN = "review-plan",
  FEEDINGPLAN = "feeding-plan"
}

export type ABVariants = Record<string, "treatment" | "control" | "off">;

export type ExperimentContext = {
    loading: boolean,
    variants: ABVariants;
}

export type ExperimentItem = {
    key: string;
    user_id?: string;
}